import React from 'react';
import Html from 'slate-html-serializer';
import sanitizeHTML from 'sanitize-html'

///These variables are primarily used by the html serializer and deserializer provided by slate
const BLOCK_TAGS = {
  p: 'paragraph',
  ul: 'bulleted-list',
  h1: 'heading-one',
  h2: 'heading-two',
  h3: 'heading-three',
  li: 'list-item',
  ol: 'numbered-list',
};

// Add a dictionary of mark tags.
const MARK_TAGS = {
  i: 'italic',
  em: 'italic',
  b: 'bold',
  strong: 'bold',
  u: 'underline',
};

const rules = [
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()];
      if (!type) return;
      return {
        object: 'block',
        type: type,
        nodes: next(el.childNodes),
      };
    },
    serialize(obj, children) {
      if (obj.object != 'block') return;
      switch (obj.type) {
        case 'paragraph':
          return <p>{children}</p>;
        case 'heading-one':
          return <h1>{children}</h1>;
        case 'heading-two':
          return <h2>{children}</h2>;
        case 'heading-three':
          return <h3>{children}</h3>;
        case 'bulleted-list':
          return <ul>{children}</ul>;
        case 'numbered-list':
          return <ol>{children}</ol>;
        case 'list-item':
          return <li>{children}</li>;
      }
    },
  },
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()];
      if (!type) return;
      return {
        object: 'mark',
        type: type,
        nodes: next(el.childNodes),
      };
    },
    serialize(obj, children) {
      if (obj.object != 'mark') return;
      switch (obj.type) {
        case 'bold':
          return <strong>{children}</strong>;
        case 'italic':
          return <em>{children}</em>;
        case 'underline':
          return <u>{children}</u>;
      }
    },
  },
];

const html = new Html({ rules });

export const getDefaultRichText = () => deserializeRichText('<p></p>');

export const serializeRichText = text => {
  return html.serialize(text);
};

export const deserializeRichText = text => {
  return html.deserialize(text);
};

export function sanitize (htmlToSanitize) {
  return sanitizeHTML(htmlToSanitize, {
    allowedTags: sanitizeHTML.defaults.allowedTags.concat(['h1', 'h2', 'u']) // defaults cover our use cases except these 3 tags
  })
}

export function htmlToString(html) {
  if (typeof html === 'string') {
    const node = (new DOMParser).parseFromString(html, 'text/html').body // decodes HTML entities created by slate
    return node.textContent
  } else {
    return html;
  }
}
