import purify from 'dompurify'

const LIST_TAGS = ['UL', 'OL']
const BLOCK_TAGS = ['BR', 'DIV', 'P', 'HR', 'H1', 'H2', 'H3']

export function getFirstLineOfHTML(htmlString) {
  if (!htmlString) return
  let body = (new DOMParser).parseFromString(purify.sanitize(htmlString), "text/html").body
  if (!body.firstChild) {
    return
  } else if (body.firstChild.nodeType === 3) { // text
    const holder = document.createElement('div')
    while (body.firstChild && !elementIsBlock(body.firstChild)) {
      holder.appendChild(body.firstChild)
    }
    return holder.innerHTML
  } else if (LIST_TAGS.includes(body.firstChild?.tagName)) {
    clearAllButFirstListItems(body.firstChild)
  } else if (BLOCK_TAGS.includes(body.firstChild?.tagName) && LIST_TAGS.includes(body.firstChild?.firstChild?.tagName)) { // list nested in block level el
    clearAllButFirstListItems(body.firstChild.firstChild)
    return body.firstChild?.innerHTML
  } else if (BLOCK_TAGS.includes(body.firstChild?.tagName) && !body.firstChild.innerHTML && body.children[1]) {
    return getFirstLineOfHTML(body.children[1])
  }
  return removeAnyBreak(body.firstChild)
}

function removeAnyBreak(node) {
  const breakIndex = node.innerHTML.indexOf('<br')
  if (breakIndex > 0) {
    return node.innerHTML.slice(0, breakIndex)
  }
  let nodeToCheck = node
  let fullSentence = BLOCK_TAGS.includes(node.tagName) ? node.innerHTML : node.outerHTML
  while (nodeToCheck.nextSibling && ![...BLOCK_TAGS, ...LIST_TAGS].includes(nodeToCheck.nextSibling.nodeName)) { //// if the first line has bold or italicized text, the next part of the same line needs to show as well
    fullSentence = fullSentence + (nodeToCheck.nextSibling.data || nodeToCheck.nextSibling.outerHTML)
    nodeToCheck = nodeToCheck.nextSibling
  }
  return fullSentence.trim()
}

function clearAllButFirstListItems(node) {
  while (node.childElementCount > 1) {
    node.removeChild(node.lastChild) // only want to show the first list item in the preview
  }
  const listItemEl = node.firstElementChild?.firstElementChild
  if (listItemEl && BLOCK_TAGS.includes(listItemEl?.tagName)) { // h1 inside of <li> found in firefox
    listItemEl.replaceWith(...listItemEl.childNodes) // replace the <h1> with its text contents
  }
  if (node.firstElementChild) {
    node.firstElementChild.classList.add('cps-ellipsis') // list item doesn't respect overflow
    node.firstElementChild.style.listStylePosition = 'inside' // overflow-hidden loses the dot of list item, this helps preserve it
  }
}

function elementIsBlock(node) {
  if (node.nodeType === 3) return false // text
  else if (node.nodeType === 1) return BLOCK_TAGS.includes(node.tagName)
  else return true // don't know why we're here, but it can't be good just stop the while loop
}