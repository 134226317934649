import { setPublicPath } from 'systemjs-webpack-interop';
setPublicPath('rich-text-ui!sofe')
import RichTextEditor from './rich-text-editor.component';
import { getDefaultRichText as defaultRT, serializeRichText as serializeRT, deserializeRichText as deserializeRT, sanitize, htmlToString as toString} from './rich-text-editor.helper.js';

export default RichTextEditor;

export const getDefaultRichText = defaultRT;
export const serializeRichText = serializeRT;
export const deserializeRichText = deserializeRT;
export const sanitizeHTML = sanitize;
export const htmlToString = toString;
export {getFirstLineOfHTML as getFirstLine} from './bandicoot-rich-text-editor/get-first-line'

export const getRichTextEditor = () => import(/* webpackChunkName: "bandicoot-rich-text-editor" */ 'src/bandicoot-rich-text-editor/bandicoot-rich-text-editor.component')
  .then(mod => mod)

export const getAddFileButton = () => import(/* webpackChunkName: "add-file-button" */ 'src/bandicoot-rich-text-editor/add-file-button.component.js').then(mod => mod)