//This is an implementation of slate, see the documentation here https://docs.slatejs.org/

import React from 'react';

import { Editor } from 'slate-react';
import { isKeyHotkey } from 'is-hotkey';

const DEFAULT_NODE = 'paragraph';

const isBoldHotkey = isKeyHotkey('mod+b');
const isItalicHotkey = isKeyHotkey('mod+i');
const isUnderlineHotkey = isKeyHotkey('mod+u');

var editorHasFocus = false;

export default class RichTextEditor extends React.Component {
  constructor(){
    super()

    this.state = {
      editorHasFocus: false,
    }
  }

  handleEditorFocus = () => {
    this.setState({editorHasFocus: true})
  };

  handleEditorBlur = () => {
    this.setState({editorHasFocus: false})
  };

  hasMark = type => {
    const { text } = this.props;
    return text ? text.activeMarks.some(mark => mark.type === type) : false;
  };

  hasBlock = type => {
    const { text } = this.props;
    return text
      ? text.blocks.some(node => {
          return node.type == type;
        })
      : false;
  };

  onChange = text => {
    this.props.onChange(text.value);
  };

  onKeyDown = (event, change) => {
    let mark;

    if (isBoldHotkey(event)) {
      mark = 'bold';
    } else if (isItalicHotkey(event)) {
      mark = 'italic';
    } else if (isUnderlineHotkey(event)) {
      mark = 'underline';
    } else {
      return;
    }

    event.preventDefault();
    change.toggleMark(mark);
    return true;
  };

  onClickMark = (event, type) => {
    event.preventDefault();
    const { text } = this.props;
    const change = text.change().toggleMark(type);
    this.onChange(change);
  };

  onClickBlock = (event, type) => {
    event.preventDefault();
    const { text } = this.props;
    const change = text.change();
    const { document } = text;

    // Handle everything but list buttons.
    if (type != 'bulleted-list' && type != 'numbered-list') {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock('list-item');

      if (isList) {
        change
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else {
        try {
          change.setBlocks(isActive ? DEFAULT_NODE : type);
        }
        catch (ex) {/* happens when setBlocks is called when the editor is not in focus - do nothing */}
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock('list-item');
      const isType = text.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type == type);
      });

      if (isList && isType) {
        change
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else if (isList) {
        change.unwrapBlock(type == 'bulleted-list' ? 'numbered-list' : 'bulleted-list').wrapBlock(type);
      } else {
        if (change.value.startBlock != null) {
          change.setBlocks && change.setBlocks('list-item').wrapBlock(type);
        }
      }
    }

    this.onChange(change);
  };

  render() {
    const width = this.props.width ? `${this.props.width}px` : '370px';
    const height = this.props.height ? `${this.props.height}px` : '105px';
    return (
      <div
        className="cps-well__light"
        style={{
          width,
          height,
          minHeight: height,
          resize: 'vertical',
          overflow: 'auto',
          border: this.state.editorHasFocus ? '1px solid var(--cps-color-primary)' : '',
          outline: this.state.editorHasFocus ? '0' : '',
        }}>
        <div className="cps-padding-4">{this.renderToolbar()}</div>
        <div
          style={{
            margin: '0px 12px',
            overflow: 'auto',
            height: 'calc(100% - 35px)',
          }}>
          {this.renderEditor()}
        </div>
      </div>
    );
  }

  renderToolbar = () => {
    return (
      <div className="menu toolbar-menu">
        {this.renderBlockButton('heading-one', 'cps-icon-header1')}
        {this.renderBlockButton('heading-two', 'cps-icon-header2')}
        {this.renderBlockButton('heading-three', 'cps-icon-header3')}
        {this.renderMarkButton('bold', 'cps-icon-bold')}
        {this.renderMarkButton('underline', 'cps-icon-underline')}
        {this.renderMarkButton('italic', 'cps-icon-italics')}
        {this.renderBlockButton('bulleted-list', 'cps-icon-list')}
        {this.renderBlockButton('numbered-list', 'cps-icon-number')}
      </div>
    );
  };

  renderMarkButton = (type, icon) => {
    return this.renderButton(icon, this.hasMark(type), event => this.onClickMark(event, type));
  };

  renderBlockButton = (type, icon) => {
    return this.renderButton(icon, this.isBlockButtonActive(type), event => this.onClickBlock(event, type));
  };

  renderButton = (icon, isActive, onMouseDown) => {
    return (
      <span
        className={`button cps-margin-left-4 cps-icon ${icon} ${isActive ? 'cps-color-primary' : ''}`}
        onMouseDown={onMouseDown}
      />
    );
  };

  isBlockButtonActive = type => {
    const { text } = this.props;
    if (type != 'bulleted-list' && type != 'numbered-list') {
      return this.hasBlock(type);
    } else {
      return text
        ? text.blocks.some(block => {
            return !!text.document.getClosest(block.key, parent => parent.type == type);
          })
        : false;
    }
  };

  renderEditor = () => {
    return (
      <div className="editor" style={{height: '100%'}}>
        <Editor
          placeholder={this.props.placeholder}
          value={this.props.text}
          onChange={this.onChange}
          onFocus={this.handleEditorFocus}
          onBlur={this.handleEditorBlur}
          onKeyDown={this.onKeyDown}
          renderNode={this.renderNode}
          renderMark={this.renderMark}
          style={{height: '100%'}}
          spellCheck
        />
      </div>
    );
  };

  renderNode = props => {
    const { attributes, children, node } = props;
    switch (node.type) {
      case 'paragraph':
        return (
          <p style={{ margin: 0 }} {...attributes}>
            {children}
          </p>
        );
      case 'heading-one':
        return <h1 {...attributes}>{children}</h1>;
      case 'heading-two':
        return <h2 {...attributes}>{children}</h2>;
      case 'heading-three':
        return <h3 {...attributes}>{children}</h3>;
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      case 'list-item':
        return <li {...attributes}>{children}</li>;
    }
  };

  renderMark = props => {
    const { children, mark } = props;
    switch (mark.type) {
      case 'bold':
        return <strong>{children}</strong>;
      case 'italic':
        return <em>{children}</em>;
      case 'underline':
        return <u>{children}</u>;
    }
  };
}
